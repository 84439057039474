import styles from '@/components/v3/cards/Card.module.scss';
import { useRef } from 'react';

const paddingCover = 20;

export default function useTicker() {
    const bodyCoverRef = useRef();
    const tickerElementRef = useRef();
    const tickersRef = useRef([]);

    const startTicker = (tickerElement) => {
        const bodyCover = /** @type {HTMLElement} */ bodyCoverRef.current;
        const maxWidth = bodyCover.offsetWidth - paddingCover;
        if (tickerElement.offsetWidth <= maxWidth) {
            return;
        }
        const title = tickerElement.innerHTML;
        const splitter = `<span class="${styles['cover__splitter']}"></span>`;
        tickerElement.innerHTML = tickerElement.innerHTML + splitter + title;
        const animation = tickerElement.animate(
            [{ transform: 'translateX(0%)' }, { transform: 'translateX(-50%) translateX(-25px)' }],
            {
                duration: tickerElement.offsetWidth * 15,
                iterations: Infinity,
            }
        );
        tickersRef.current.push({
            tickerElement: tickerElement,
            animation: animation,
            title: title,
        });
    };

    const enterHandler = () => {
        const bodyCover = /** @type {HTMLElement} */ bodyCoverRef.current;
        const tickerElement = /** @type {HTMLElement} */ tickerElementRef.current;
        if (!bodyCover || !tickerElement) {
            return false;
        }
        startTicker(tickerElement);
    };

    const leaveHandler = () => {
        for (let ticker of tickersRef.current) {
            if (ticker.tickerElement) {
                ticker.tickerElement.innerHTML = ticker.title;
            }
            ticker.animation?.cancel();
        }
        tickersRef.current = [];
    };

    return [enterHandler, leaveHandler, bodyCoverRef, tickerElementRef];
}
