import { usePinStore } from '@/src/stores/RootStoreProvider';
import useModals from '@/src/hooks/useModals';
import { PinReason } from '@/src/constants/pin-reason';
import { ADULT_SESSION_TTL } from '@/src/stores/PinStore';
import ModalPinCodeSuccess from '@/components/modal/pin/ModalPinCodeSuccess';
import useNotifications from '@/src/hooks/useNotifications';

export default function usePin() {
    const { openModal } = useModals();
    const { showSuccess } = useNotifications();
    const {
        setExpiredDate,
        setPinCode,
        pinStatus,
        setPinStatus,
        setAdultSessionExpiredDate,
        setIsTvSession,
        setIsTvAdultSession,
        setPinChecked,
    } = usePinStore();

    const onPinEnabled = (status, pinReason, isTv) => {
        setPinStatus(status);
        if (!isTv) {
            if (pinReason === PinReason.ADULT) {
                const adultSessionExpiredDate = new Date();
                adultSessionExpiredDate.setSeconds(adultSessionExpiredDate.getSeconds() + ADULT_SESSION_TTL);
                setAdultSessionExpiredDate(new Date(adultSessionExpiredDate));
            }
            if ([PinReason.INAPPROPRIATE_CONTENT, PinReason.ADULT].includes(pinReason)) {
                const expiredDate = new Date();
                expiredDate.setSeconds(expiredDate.getSeconds() + pinStatus.pinSessionTTL);
                setExpiredDate(expiredDate);
            }
        }
        openModal(<ModalPinCodeSuccess />);
    };

    const onPinChecked = (pinCode, pinReason, isTv) => {
        setPinCode(pinCode);
        if (isTv) {
            setTimeout(() => {
                setIsTvSession(true);
            }, 0);
            if (pinReason === PinReason.ADULT) {
                setIsTvAdultSession(true);
            }
            setPinChecked(true);
        } else {
            if (pinReason === PinReason.ADULT) {
                const expiredDate = new Date();
                expiredDate.setSeconds(expiredDate.getSeconds() + ADULT_SESSION_TTL);
                setAdultSessionExpiredDate(new Date(expiredDate));
            }
            if ([PinReason.INAPPROPRIATE_CONTENT, PinReason.ADULT].includes(pinReason)) {
                const expiredDate = new Date();
                expiredDate.setSeconds(expiredDate.getSeconds() + pinStatus.pinSessionTTL);
                setExpiredDate(new Date(expiredDate));
            }
        }
    };

    const onPinReset = (status) => {
        setPinStatus(status);
        setExpiredDate(null);
        setAdultSessionExpiredDate(null);
        showSuccess('Новый пин-код установлен');
    };

    return {
        onPinEnabled,
        onPinChecked,
        onPinReset,
    };
}
