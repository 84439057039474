import styles from './Card.module.scss';
import catalogStyles from '@/components/catalog/Catalog.module.scss';
import useTicker from '@/src/hooks/useTicker';
import Icon from '@/components/icon/Icon';
import { usePinStore } from '@/src/stores/RootStoreProvider';
import Link from 'next/link';
import PinChecker from '@/src/checkers/PinChecker';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import {
    CHANNELS_FILTER_PACKAGES_LOCAL_STORAGE_KEY,
    CHANNELS_FOLDER_LOCAL_STORAGE_KEY,
    FILTER_CHANNEL_PACKAGES,
} from '@/src/helpers/DataHelper';
import CardBadge from '@/components/v3/cards/common/CardBadge';

/**
 * @param {Channel} model
 * @param {string} cardClasses
 */
function ChannelCard({ model, cardClasses }) {
    const [enterHandler, leaveHandler, bodyCoverRef, tickerElementRef] = useTicker();
    const router = useRouter();
    const { pinStatus } = usePinStore();

    let classNames = [];
    classNames.push(cardClasses);
    if (!model.isAvailable()) {
        classNames.push(styles['cover--icon']);
    }

    const onClick = () => {
        if (window.location.pathname.startsWith('/channel-packages')) {
            localStorage.setItem(CHANNELS_FOLDER_LOCAL_STORAGE_KEY, FILTER_CHANNEL_PACKAGES);
            localStorage.setItem(CHANNELS_FILTER_PACKAGES_LOCAL_STORAGE_KEY, router.query.all.slice(-1)[0]);
        }
    };

    const getViewPinReason = () => {
        return model.getPinReason(pinStatus);
    };

    return (
        <article
            className={catalogStyles['cover'] + ' ' + styles['cover'] + ' cover--channels ' + classNames.join(' ')}
            onMouseEnter={enterHandler}
            onMouseLeave={leaveHandler}
        >
            <PinChecker checked={getViewPinReason} isTv={true}>
                <Link href={model.getTVUrl()} className={'cover__wrapper cover__wrapper--int'} onClick={onClick}>
                    <div className={styles['cover__poster']}>
                        <div className="cover__skeleton cover__skeleton--poster" />
                        <img
                            className={styles['cover__image'] + ' ' + 'lazyload'}
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-src={model.getImageSrc()}
                            aria-label={model.title}
                            alt={model.title}
                            style={{
                                background: model.backgroundColor,
                            }}
                        />
                    </div>
                    <div className={styles['cover__body']} ref={bodyCoverRef}>
                        <div className={styles['cover__content']}>
                            {!model.isAvailable() && <Icon id={'lock'} modifier={'icon--lock cover__icon'} />}
                            <div className="cover__label-wrapper">
                                <div className={'cover__label'} ref={tickerElementRef}>
                                    {model.title}
                                </div>
                            </div>
                        </div>
                        <div className="cover__badges">
                            <CardBadge model={model} offers={model.packages[0]?.offers ?? model.offers} />
                        </div>
                    </div>
                </Link>
            </PinChecker>
        </article>
    );
}

export default observer(ChannelCard);
