import { useEffect, useState } from 'react';
import ModalForm from '@/components/modal/ModalForm';
import InputPinCode from '@/components/input/InputPinCode';
import { useCheckSMS } from '@/components/modal/pin/ModalPinCode';
import Loader from '@/components/modal/helpers/Loader';
import { LAYERS, modalViewEvent } from '@/src/modules/events/GTM';

/**
 * @param {string} action
 * @param {string} pinCode
 * @param {function} callback
 */
export default function ModalPinCodeConfirmPin({ action, pinCode, callback }) {
    const { checkSMS } = useCheckSMS();
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        modalViewEvent('ModalPinCodeConfirmPin', LAYERS.MODAL_PIN_CODE);
    }, []);

    async function onConfirm(code) {
        if (code !== pinCode) {
            setError('Пин-коды не совпадают');
            return;
        }
        setSubmitting(true);
        try {
            await checkSMS(action, callback, pinCode);
        } catch (e) {
            setError(e.message);
            setSubmitting(false);
        }
    }

    function onChange(newValue) {
        setValue(newValue);
        setError('');
        if (newValue.length === 4) {
            // noinspection JSIgnoredPromiseFromCall
            onConfirm(newValue);
        }
    }

    return (
        <ModalForm
            closeButton={true}
            pageUrl={'#pin-code-confirm'}
            pageTitle={'Подтвердите пин-код'}
            className={'modal--primary modal--parental-preview'}
        >
            <div className="modal__img-wrapper modal__img-wrapper--shadow">
                <img className="modal__img" src="/assets/img/modal-parental-control.jpg" alt="" />
            </div>
            <form className="modal__form">
                <div className="modal__content-title modal__content-title--center">Подтвердите пин-код</div>
                <div className="modal__fieldset">
                    <div className="modal__field">
                        {submitting ? (
                            <Loader />
                        ) : (
                            <InputPinCode
                                className={'input--code-large'}
                                value={value}
                                error={error}
                                onChange={onChange}
                            />
                        )}
                    </div>
                </div>
            </form>
        </ModalForm>
    );
}
