import React from 'react';
import { useAuthStore } from '@/src/stores/RootStoreProvider';
import useModals from '@/src/hooks/useModals';
import ModalAuthorization from '@/components/modal/authorization/ModalAuthorization';

export default function AuthChecker({ condition = true, title, backgroundSrc, children, onBeforeCheck }) {
    const { openModal } = useModals();
    const { isGuest } = useAuthStore();

    const onClick = (event) => {
        if (condition && isGuest) {
            event.stopPropagation();
            event.preventDefault();
            openModal(<ModalAuthorization title={title} backgroundSrc={backgroundSrc} />);
        } else if (typeof originalOnClick === 'function') {
            originalOnClick(event);
        }
    };

    const child = React.Children.only(children);
    const originalOnClick = child.props.onClick;

    const childProps = {
        onClick: (e) => {
            if (typeof onBeforeCheck === 'function') {
                onBeforeCheck();
            }
            onClick(e);
        },
    };
    return React.cloneElement(child, childProps);
}
