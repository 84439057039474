/**
 * @param {BaseModel} model
 * @param {Offers} offers
 */
export default function CardBadge({ model, offers }) {
    const offer = offers.offer;
    if (model.isAvailable()) {
        return null;
    }
    if (offers.isFree()) {
        return <div className="cover__status cover__status--free">Доступно</div>;
    }
    if (offers.isTNBAvailable()) {
        return <div className="cover__status cover__status--sale">Попробуйте {offer.stock.getPeriodText()}</div>;
    }
    if (offers.getDiscount()) {
        return <div className="cover__status cover__status--sale">Скидка {offers.getDiscount()} %</div>;
    }
    return null;
}
