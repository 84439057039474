import React, { useRef } from 'react';
import ModalPinCode, { ModalPinCodeAction } from '@/components/modal/pin/ModalPinCode';
import { usePinStore } from '@/src/stores/RootStoreProvider';
import useModals from '@/src/hooks/useModals';
import ModalPinCodePinEnable from '@/components/modal/pin/ModalPinCodePinEnable';
import { PinReason } from '@/src/constants/pin-reason';
import usePin from '@/src/hooks/usePin';

export default function PinChecker({
    capturedEventType = 'onClick',
    checked = true,
    isTv = false,
    callback,
    children,
    onBeforeCheck,
}) {
    const { openModal } = useModals();
    const { pinStatus } = usePinStore();
    const { onPinEnabled, onPinChecked } = usePin();

    const isBypassedEventRef = useRef(false);

    const onClick = (event) => {
        const pinReason = typeof checked === 'function' ? checked() : checked;
        if (!pinReason || isBypassedEventRef.current) {
            if (typeof originalOnClick === 'function') {
                originalOnClick(event);
            }
            return;
        }
        event.stopPropagation();
        event.preventDefault();

        if (pinStatus?.isEnabled()) {
            openModal(
                <ModalPinCode
                    callback={(pinCode) => {
                        onPinChecked(pinCode, pinReason, isTv);
                        if (typeof callback === 'function') {
                            callback(event);
                        } else if (event.target) {
                            isBypassedEventRef.current = true;
                            event.target.dispatchEvent(new MouseEvent('click', event.nativeEvent));
                            isBypassedEventRef.current = false;
                        }
                    }}
                    showDisablePinButton={pinReason === PinReason.INAPPROPRIATE_CONTENT}
                />
            );
        } else {
            openModal(
                <ModalPinCodePinEnable
                    callback={(status) => {
                        onPinEnabled(status, pinReason, isTv);
                    }}
                    action={ModalPinCodeAction.PIN_ENABLE_FROM_ADULT_SOURCE}
                />
            );
        }
    };

    const child = React.Children.only(children);
    const originalOnClick = child.props.onClick;

    const childProps = {
        onClick: null,
        [capturedEventType]: (e) => {
            if (typeof onBeforeCheck === 'function') {
                onBeforeCheck();
            }
            onClick(e);
        },
    };
    return React.cloneElement(child, childProps);
}
