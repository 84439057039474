import { ButtonClear, ButtonSecondary } from '@/components/button/Button';
import { useRouter } from 'next/router';
import ModalForm from '@/components/modal/ModalForm';
import useModals from '@/src/hooks/useModals';
import { clickButtonEvent, LAYERS, modalViewEvent } from '@/src/modules/events/GTM';
import { useEffect } from 'react';

export default function ModalPinCodeSuccess() {
    const { closeModal } = useModals();
    const router = useRouter();

    useEffect(() => {
        modalViewEvent('ModalPinCodeSuccess', LAYERS.MODAL_PIN_CODE);
    }, []);

    const onConfirm = async () => {
        clickButtonEvent('goToSettings', LAYERS.MODAL_PIN_CODE);
        await router.push('/profile/pin-code');
    };

    const onClose = () => {
        clickButtonEvent('Close', LAYERS.MODAL_PIN_CODE);
        closeModal();
    };

    return (
        <ModalForm
            closeButton={true}
            pageUrl={'#pin-code'}
            pageTitle={'Пин-код установлен'}
            className={'modal--primary modal--parental-preview'}
        >
            <img
                className="modal__icon"
                src="/assets/img/icon-parental-control-success.svg"
                width="136"
                height="136"
                alt=""
            />
            <div className="modal__content-title modal__content-title--center">Пин-код установлен</div>
            <div className="modal__content-subtitle">
                Будем запрашивать его каждый раз, когда кто-то захочет посмотреть взрослое кино. Отключить пин-код можно
                в&nbsp;настройках.
            </div>
            <div className="modal__btnset">
                <div className="modal__wrapper-btn">
                    <ButtonSecondary title={'Закрыть'} onClick={onClose} />
                </div>
                <div className="modal__wrapper-btn">
                    <ButtonClear title={'Перейти в настройки'} onClick={onConfirm} />
                </div>
            </div>
        </ModalForm>
    );
}
