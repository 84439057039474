import styles from '../Modal.module.scss';
import { useRef, useState } from 'react';
import ModalForm from '../ModalForm';
import InputPinCode from '@/components/input/InputPinCode';
import { ButtonPrimary } from '@/components/button/Button';
import ModalPinCodeConfirmPin from '@/components/modal/pin/ModalPinCodeConfirmPin';
import useModals from '@/src/hooks/useModals';
import { ModalPinCodeAction } from '@/components/modal/pin/ModalPinCode';
import { clickButtonEvent, LAYERS } from '@/src/modules/events/GTM';

export default function ModalPinCodePinEnable({ callback, action }) {
    const { openModal } = useModals();
    const [pinCode, setPinCode] = useState('');
    const [error, setError] = useState(null);
    const pinCodeInputRef = useRef(null);

    const onPinInputChanged = (newPinCode) => {
        setPinCode(newPinCode);
        setError(null);
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        clickButtonEvent('setPin', LAYERS.MODAL_PIN_CODE);
        openModal(<ModalPinCodeConfirmPin action={action} pinCode={pinCode} callback={callback} />);
    };

    let subtitle;
    let title;
    switch (action) {
        case ModalPinCodeAction.PIN_ENABLE:
            title = 'Установите пин-код';
            subtitle = (
                <>
                    Придумайте пин-код, чтобы подтверждать покупки, настраивать родительский контроль и ограничивать
                    доступ несовершеннолетних к нежелательному контенту
                </>
            );
            break;
        case ModalPinCodeAction.PIN_ENABLE_FROM_ADULT_SOURCE:
            title = 'Установите пин-код';
            subtitle = (
                <>
                    Чтобы посмотреть взрослое кино, которое некоторые пользователи могут счесть неприемлемым, нужно
                    установить пин-код
                    <br />и подтвердить номер телефона
                </>
            );
            break;
        case ModalPinCodeAction.PIN_RESET:
            title = 'Придумайте новый пин-код';
            break;
    }

    return (
        <ModalForm
            closeButton={true}
            pageUrl={'#pin-code'}
            pageTitle={'Установите пин-код'}
            className={'modal--primary modal--parental-preview'}
        >
            <div className="modal__img-wrapper modal__img-wrapper--shadow">
                <img className="modal__img" src="/assets/img/modal-parental-control.jpg" alt="" />
            </div>
            <form className="modal__form" onSubmit={onSubmit}>
                <div className="modal__content-title modal__content-title--center">{title}</div>
                {subtitle && <div className="modal__content-subtitle">{subtitle}</div>}
                <div className={'modal__fieldset'}>
                    <div className={styles['modal__field']}>
                        <InputPinCode
                            className={'input--code-large'}
                            inputRef={pinCodeInputRef}
                            value={pinCode}
                            error={error}
                            onChange={onPinInputChanged}
                            onFocus={() => setError('')}
                            type={'password'}
                        />
                    </div>
                </div>
                <div className="modal__btnset">
                    <div className="modal__wrapper-btn">
                        <ButtonPrimary title={'Установить'} type="submit" disabled={pinCode.length < 4} />
                    </div>
                </div>
            </form>
        </ModalForm>
    );
}
